import $ from 'jquery';
window.$ = window.jQuery = $;

require('./bootstrap');
require('./owl.carousel');

const homeNewsCarousel = $('.news-carousel')
homeNewsCarousel.owlCarousel({
    loop:true,
    margin:0,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:false
        },
        600:{
            items:2,
            nav:false
        },
        1000:{
            items:3,
            nav:false,
        }
    }
});
$('#news-next-btn').click(function() {
    homeNewsCarousel.trigger('next.owl.carousel');
})
$('#news-prev-btn').click(function() {
    homeNewsCarousel.trigger('prev.owl.carousel', [300]);
})
